<template>
  <div :style="{height: '100%'}">
    <iq-card :style="{height: '100%', boxShadow: 'none'}">
      <ListForm color="#fff" :getMore="getMore" @setBusy="setBusy" @setItem="setItem" :title=" item+ ' > ' + category" :items="items" :isBusy="isBusy" :fieldsType="fieldsType" :total="total"/>
    </iq-card>
  </div>
</template>
<script>
import { core } from '../../config/pluginInit'
import ListForm from './Form/ListForm.vue'
import posted from '../../services/dashboard/posted'

export default {
  name: 'DashboardList',
  components: { ListForm },
  mounted () {
    this.getList()
    core.index()
  },
  methods: {
    getList () {
      posted.getList(this.category, 10, 0, '').then(res => {
        if (res.status !== 200) {
          return alert('서버 오류')
        }
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          this.setBusy()
          return
        }
        this.setBusy()
        if (res.data.community.length > 0) {
          this.fieldsType = 'list'
          this.items = res.data.community
          this.total = res.data.total
        } else {
          this.fieldsType = 'span'
          this.items = [{ pk: '', title: '작성된 글이 없습니다.' }]
        }
      })
    },
    getMore (page, search) {
      return posted.getList(this.category, 10, page, search)
    },
    setItem (item, total) {
      this.items = item
      this.total = total
    },
    setBusy () {
      this.isBusy = !this.isBusy
    }
  },
  watch: {
    $route (to, from) {
      if (to.params !== from.params) {
        this.item = to.params.item
        this.category = to.params.category
        this.setBusy()
        this.getList()
      }
    }
  },
  data () {
    return {
      category: this.$route.params.category,
      item: this.$route.params.item,
      isBusy: true,
      items: [],
      total: 0,
      fieldsType: '',
      search: ''
    }
  }
}
</script>
<style scoped>
.progress-bar-info {
  background-color: #0cd2e3 !important;
}
.progress-bar-success {
  background-color: #1be1b3 !important;
}
.progress-bar-warn {
  background-color: #f4b72a !important;
}
.progress-bar-pink {
  background-color: #fd6c9e !important;
}
</style>
